<template>
    <section class="content-wrapper">
        <div class="content-header">
            <h1>
                Subscription Plans
            </h1>
        </div>
        <section v-if="subscription" class="content">
            <div class="col-12 col-lg-12">
                <div class="box box-default">
                    <div class="box-header with-border">
                    <h4 class="box-title">{{ subscription.name }} Plan</h4>
                    </div>
                    <!-- /.box-header -->
                    <div class="box-body">
                        <!-- Nav tabs -->
                        <ul class="nav nav-tabs customtab" role="tablist">
                            <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#overview" role="tab"><span class="hidden-sm-up"><i class="ion-home"></i></span> <span class="hidden-xs-down">Overview</span></a> </li>
                            <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#current-subscribed-customers" role="tab"><span class="hidden-sm-up"><i class="ion-email"></i></span> <span class="hidden-xs-down">Current Subscribed Customers</span></a> </li>
                            <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#subscription-history" role="tab"><span class="hidden-sm-up"><i class="ion-email"></i></span> <span class="hidden-xs-down">Subscription History</span></a> </li>
                        </ul>
                        <!-- Tab panes -->
                        <div class="tab-content">
                            <div class="tab-pane active" id="overview" role="tabpanel">
                                <SubscriptionOverview :subscription="subscription"/>
                            </div>
                            <div class="tab-pane pad" id="current-subscribed-customers" role="tabpanel">
                                <CurrentSubscribedCustomers :subscription="subscription"/>
                            </div>
                            <div class="tab-pane pad" id="subscription-history" role="tabpanel">
                                <SubscriptionHistory :subscription="subscription"/>
                            </div>
                        </div>
                    </div>
                    <!-- /.box-body -->
                </div>
                <!-- /.box -->
                </div>
        </section>
    </section>
</template>

<script>
import { repository } from '../../presenter';
import SubscriptionOverview from '../../components/Subscription/SubscriptionOverview'
import CurrentSubscribedCustomers from '../../components/Subscription/CurrentSubscribedCustomers'
import SubscriptionHistory from '../../components/Subscription/SubscriptionHistory'
export default {
    components: {
        SubscriptionOverview,
        CurrentSubscribedCustomers,
        SubscriptionHistory,
    },
    props:['subscriptionData'],
    mounted() {
        if (this.subscriptionData==null){
            let subscriptionID = this.$route.params.subscription_id;
            this.loadSubscriptionData(subscriptionID);
        }
    },
    data() {
        return{
            subscription: this.subscriptionData,
        }
    },
    methods: {
        async loadSubscriptionData(subscriptionID) {
            let response = await repository.subscription.fetchSubscription(subscriptionID);
            console.log("subscription data is ", response)
            if (response.success) {
                this.subscription = response.data;
                return;
            }
            this.$router.push('/view-subscriptions');
        }
    },
}
</script>

