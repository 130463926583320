<template>
    <section class="content">
        <div class="row">
            <div class="col-12">
                <form @submit.prevent="onSubmit">
                    <div class="box bg-pale-secondary box-outline-dark">
                        <div class="box-body">
                            <div class="contact-page-aside">
                                <ul class="list-style-none list-inline font-size-16">
                                    <li>
                                        <div class="form-group">
                                            <div class="controls">
                                                <input v-model="email" type="text" name="text" class="form-control" placeholder="Email">
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="form-group">
                                            <div class="controls">
                                                <input v-model="name" type="text" name="text" class="form-control" placeholder="Name">
                                            </div>
                                        </div>
                                    </li>
                                    <li class="box-label float-right"><button class="btn btn-primary text-white mt-10" @click="fetchCustomer">Search</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- /. box -->
                </form>
            </div>
            <!-- /.col -->

            <div class="col-12">
            <div class="box box-solid bg-primary">
                <div class="box-header with-border">
                    <h4 class="box-title">Customers</h4>
                </div>
                <div class="box-body">
                <div class="table-responsive">
                    <table id="employeelist" class="table table-hover no-wrap" data-page-size="10">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(userSubscription, index) in userSubscriptionList" :key="userSubscription.id">
                                <td>{{ index+1 }}</td>
                                <td>{{ userSubscription.user.account_type=='business' ? userSubscription.user.business_name : `${userSubscription.user.first_name} ${userSubscription.user.last_name}` }}</td>
                                <td>{{ userSubscription.user.email }}</td>
                                <td>{{ userSubscription.user.phone ? userSubscription.user.phone : 'not available' }}</td>
                                <td>
                                    <router-link :to="{path: `/customer/${userSubscription.user.id}`}" class="btn btn-success btn-sm"><i class="ti-eye" aria-hidden="true">View</i></router-link>
                                </td>
                            </tr>
                            
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="2">
                                    <!-- <button type="button" class="btn btn-success" data-toggle="modal" data-target="#add-customer">Add New Customer</button> -->
                                </td>
                                <td colspan="3">
                                    <div class="text-right">
                                        <ul class="pagination"> </ul>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                </div>
                </div>
                <!-- /.box-body -->
            </div>
            <!-- /. box -->























            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->

    </section>
</template>

<script>
import { repository } from '../../presenter'
import { mapGetters } from 'vuex'
export default {
  props: ['subscription'],
  data () {
    return {
      email: '',
      name: '',
    }
  },
  computed: {
    ...mapGetters('subscription', {
        userSubscriptionList: 'retrieveHistoryForASubscription',
    })
  },
  mounted () {
    // const datatable = document.createElement('script')
    // datatable.setAttribute('src', '/assets/styles/vendor_components/datatable/datatables.min.js')
    // document.head.appendChild(datatable)
    // datatable.onload = function(){
    //     const dataTable2JS = document.createElement('script')
    //     dataTable2JS.setAttribute('src', '/assets/js/pages/data-table.js')
    //     document.head.appendChild(dataTable2JS)
    // }

    

    this.loadSubscriptionHistory(this.subscription.id)
    // this.fetchCustomer(null)
  },
  methods: {
    loadSubscriptionHistory (subscriptionID) {
      const data = {
        subscriptionID: subscriptionID,
        email: this.email,
        name: this.name,
      }
      repository.subscription.loadSubscriptionHistory(data)
    },
  }
}
</script>

